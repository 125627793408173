import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Btn } from '../Button/Button';

const Table = styled.div`
  background-color: #fff;
  display: grid;
  grid-template-rows: auto 1fr;
`;

const DataContainer = styled.div`
  background-color: ${(props) => (props.isOpen ? 'var(--tertiary-color)' : 'transparent')};
  border: 1px solid transparent;
  border-bottom: 1px solid #eff7ff;
  cursor: pointer;
  outline: none;

  ${(props) => props.home && 'border: none;'}

  &:hover {
    background-color: ${(props) => (props.isOpen ? 'var(--tertiary-color)' : 'var(--body-bgcolor)')};
  }

  &:focus {
    border-color: var(--primary-color);
  }
`;

const Row = styled.div`
  border-bottom: 1px solid var(--tertiary-color);
  display: flex;
  justify-content: space-between;
`;

const Header = styled(Row)`
  background-color: var(--secondary-color);
  color: var(--white);
  grid-area: 1 / 1 / 1 / 1;

  @media (min-width: 1024px) {
    border-radius: 8px 8px 0 0;
  }
`;

const Body = styled.div`
  border-bottom: 1px solid #eff7ff;
  grid-area: 2 / 1 / 2 / 1;
  overflow: auto;

  ${({ listView }) =>
    listView &&
    `
    height: calc(100vh - 6rem - 144px);

    @media (min-width: 1024px) {
      height: calc(100vh - 13.75rem - 144px);
    }
  `};
`;

const Col = styled.div`
  flex: ${(props) => props.size || 1};
  overflow: hidden;
  text-overflow: ${(props) => (props.noEllipsis ? 'clip' : 'ellipsis')};

  ${(props) => props.center && 'display: grid; justify-content: center;'};
  ${(props) =>
    props.right &&
    `
    display: grid;
    justify-content: flex-end;

    button {
      justify-content: flex-end;
      flex-direction: row-reverse;
      gap: 0.5rem;
    }
    `};
  ${(props) => (props.small ? 'padding: 0.75rem 0.5rem;' : 'padding: 0.75rem 1rem;')};

  ${({ scroll }) => scroll && 'overflow-x: scroll;'}
  ${({ gap }) =>
    gap &&
    `
      align-items: flex-start;
      display: flex;
      gap: ${gap}rem;
      justify-content: center;
  `}
  ${({ leftMargin }) => leftMargin && 'margin-left: 0.5rem;'}
  ${({ noRightPadding }) => noRightPadding && 'padding-right: 0;'}
  ${({ relative }) => relative && 'overflow: visible; position: relative;'}
`;

const Button = (props) => {
  const onClick = (event) => {
    event.stopPropagation();
    props.onClick && props.onClick(event);
  };
  return <Btn size="sm" type="button" {...props} onClick={onClick} />;
};

const Container = ({ children, onClick, ...props }) => {
  const onKeyUp = ({ keyCode }) => keyCode === 13 && onClick();
  return (
    <DataContainer onClick={onClick} onKeyUp={onKeyUp} role="button" tabIndex="0" {...props}>
      {children}
    </DataContainer>
  );
};

Container.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
};

Table.Row = Row;
Table.Header = Header;
Table.Body = Body;
Table.Col = Col;
Table.Button = Button;
Table.Container = Container;

export default Table;
