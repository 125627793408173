/**
 * @generated SignedSource<<0e37bcea23b5e40dba26a14347bdc348>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "score",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "strokes",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "finishedHoles",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "flightStatus",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nationality",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TeamStrokeplayLeaderboardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tournament",
        "kind": "LinkedField",
        "name": "tournament",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TeamStrokeplayLeaderboardTable_tournament"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TeamStrokeplayLeaderboardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tournament",
        "kind": "LinkedField",
        "name": "tournament",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activeRound",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "databaseId",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "teamStrokeplay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StrokeplayTeam",
            "kind": "LinkedField",
            "name": "rankedStrokeplayTeams",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "position",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numberOfHoles",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "playersCount",
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "FlightScore",
                "kind": "LinkedField",
                "name": "activeFlightScores",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Player",
                "kind": "LinkedField",
                "name": "players",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TournamentPlayer",
                "kind": "LinkedField",
                "name": "tournamentPlayers",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FlightScore",
                    "kind": "LinkedField",
                    "name": "activeFlightScore",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Flight",
                        "kind": "LinkedField",
                        "name": "flight",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startDate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startingHole",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Player",
                    "kind": "LinkedField",
                    "name": "player",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v9/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TournamentRound",
            "kind": "LinkedField",
            "name": "activeTournamentRound",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shotgun",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamStrokeplayConfiguration",
            "kind": "LinkedField",
            "name": "teamStrokeplayConfiguration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "includedScores",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organisation",
            "kind": "LinkedField",
            "name": "organisation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4c8f1f81a6f555246ba7654cf924c8dc",
    "id": null,
    "metadata": {},
    "name": "TeamStrokeplayLeaderboardQuery",
    "operationKind": "query",
    "text": "query TeamStrokeplayLeaderboardQuery(\n  $id: ID!\n) {\n  tournament(id: $id) {\n    ...TeamStrokeplayLeaderboardTable_tournament\n    id\n  }\n}\n\nfragment FinishedHoles_tournament on Tournament {\n  status\n  activeTournamentRound {\n    shotgun\n    id\n  }\n}\n\nfragment Position_tournament on Tournament {\n  activeRound\n}\n\nfragment ScoreTotal_tournament on Tournament {\n  activeRound\n  organisation {\n    color\n    id\n  }\n}\n\nfragment TeamStrokeplayCollapse_strokeplayTeam on StrokeplayTeam {\n  tournamentPlayers {\n    id\n    score\n    strokes\n    activeFlightScore {\n      finishedHoles\n      flightStatus\n      status\n      flight {\n        startDate\n        startingHole\n        id\n      }\n      id\n    }\n    player {\n      name\n      nationality\n      id\n    }\n  }\n}\n\nfragment TeamStrokeplayCollapse_tournament on Tournament {\n  activeRound\n  teamStrokeplayConfiguration {\n    includedScores\n    id\n  }\n  ...FinishedHoles_tournament\n  ...ScoreTotal_tournament\n}\n\nfragment TeamStrokeplayLeaderboardTableEntry_rankedStrokeplayTeam on StrokeplayTeam {\n  name\n  numberOfHoles\n  playersCount\n  position\n  score\n  strokes\n  activeFlightScores {\n    finishedHoles\n    flightStatus\n    id\n  }\n  players {\n    nationality\n    id\n  }\n  ...TeamStrokeplayCollapse_strokeplayTeam\n}\n\nfragment TeamStrokeplayLeaderboardTableEntry_tournament on Tournament {\n  activeRound\n  ...FinishedHoles_tournament\n  ...TeamStrokeplayCollapse_tournament\n  ...Position_tournament\n  ...ScoreTotal_tournament\n}\n\nfragment TeamStrokeplayLeaderboardTable_tournament on Tournament {\n  activeRound\n  databaseId\n  status\n  teamStrokeplay\n  rankedStrokeplayTeams {\n    id\n    position\n    ...TeamStrokeplayLeaderboardTableEntry_rankedStrokeplayTeam\n  }\n  ...TeamStrokeplayLeaderboardTableEntry_tournament\n  id\n}\n"
  }
};
})();

node.hash = "5c5ab867caba45fbe19176d34075317e";

module.exports = node;
