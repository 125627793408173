/**
 * @generated SignedSource<<9f11d2cb5ada43822958cbc50916fe8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nationality",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxSize",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "includedScores",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StrokeplayTeamsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tournament",
        "kind": "LinkedField",
        "name": "tournament",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Player",
            "kind": "LinkedField",
            "name": "players",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "StrokeplayTeamsPlayer_player"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StrokeplayTeam",
            "kind": "LinkedField",
            "name": "strokeplayTeams",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Player",
                "kind": "LinkedField",
                "name": "players",
                "plural": true,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "StrokeplayTeam_strokeplayTeam"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamStrokeplayConfiguration",
            "kind": "LinkedField",
            "name": "teamStrokeplayConfiguration",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "StrokeplayTeamsConfiguration_teamStrokeplayConfiguration"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StrokeplayTeam_tournament"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StrokeplayTeamsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tournament",
        "kind": "LinkedField",
        "name": "tournament",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Player",
            "kind": "LinkedField",
            "name": "players",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "tournamentId",
                    "variableName": "id"
                  }
                ],
                "kind": "ScalarField",
                "name": "tournamentReservationTeam",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StrokeplayTeam",
            "kind": "LinkedField",
            "name": "strokeplayTeams",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Player",
                "kind": "LinkedField",
                "name": "players",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamStrokeplayConfiguration",
            "kind": "LinkedField",
            "name": "teamStrokeplayConfiguration",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1ea0fadd3ade19c31ed43addfa218914",
    "id": null,
    "metadata": {},
    "name": "StrokeplayTeamsQuery",
    "operationKind": "query",
    "text": "query StrokeplayTeamsQuery(\n  $id: ID!\n) {\n  tournament(id: $id) {\n    databaseId\n    id\n    players {\n      category\n      databaseId\n      name\n      nationality\n      ...StrokeplayTeamsPlayer_player\n      id\n    }\n    strokeplayTeams {\n      databaseId\n      players {\n        databaseId\n        id\n      }\n      ...StrokeplayTeam_strokeplayTeam\n      id\n    }\n    teamStrokeplayConfiguration {\n      maxSize\n      includedScores\n      ...StrokeplayTeamsConfiguration_teamStrokeplayConfiguration\n      id\n    }\n    ...StrokeplayTeam_tournament\n  }\n}\n\nfragment StrokeplayTeam_strokeplayTeam on StrokeplayTeam {\n  databaseId\n  id\n  name\n  players {\n    databaseId\n    name\n    nationality\n    id\n  }\n}\n\nfragment StrokeplayTeam_tournament on Tournament {\n  teamStrokeplayConfiguration {\n    includedScores\n    maxSize\n    id\n  }\n}\n\nfragment StrokeplayTeamsConfiguration_teamStrokeplayConfiguration on TeamStrokeplayConfiguration {\n  id\n  includedScores\n  maxSize\n}\n\nfragment StrokeplayTeamsPlayer_player on Player {\n  category\n  databaseId\n  name\n  nationality\n  tournamentReservationTeam(tournamentId: $id)\n}\n"
  }
};
})();

node.hash = "f9eec6d5213988522d4c161321a90aca";

module.exports = node;
