import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { IconButton } from '../Button';

export const duration = 300;

export const Dialog = styled.div`
  inset: 0;
  overflow: scroll;
  position: absolute;
  z-index: 1040;
`;

export const Content = styled.div`
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  margin: 10px auto;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  padding: ${({ noPadding }) => (noPadding ? '0 0 2rem 0' : '2rem')};
  position: relative;
  transform: translate(0, ${({ state }) => (['entering', 'exiting'].includes(state) ? '-25%' : 0)});
  transition-property: transform, opacity;
  transition-duration: ${duration}ms;
  transition-timing-function: ease-out;
  z-index: 1;

  @media (min-width: 768px) {
    margin: 2rem auto;
    width: 600px;
  }

  @media (min-width: 992px) {
    margin: 2rem auto;
    width: 770px;
  }

  ${({ small }) => small && 'max-width: 342px;'};
`;

export const Backdrop = styled.aside`
  background-color: var(--black);
  bottom: 0;
  left: 0;
  position: fixed;
  opacity: ${({ state }) => (state === 'entered' ? 0.5 : 0)};
  right: 0;
  top: 0;
  transition: opacity ${duration}ms ease;
`;

const HeaderContainer = styled.div`
  border-bottom: 1px solid hsl(209, 75%, 95%);
  display: flex;
  justify-content: space-between;
  padding: ${({ noPadding }) => (noPadding ? '2rem 2rem 1rem 2rem' : '0 0 1rem 0')};
`;

const Title = styled.h4`
  margin: 0;
`;

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
`;

export const Body = styled.div`
  margin: 1.5rem 0;

  ${({ autoOverflow }) => autoOverflow && 'overflow: auto;'};

  ${({ scroll }) =>
    scroll &&
    `
  overflow-y: scroll;
  max-height: 70vh;
  `};
`;

export const Footer = styled.div`
  align-items: center;
  border-top: 1px solid hsl(209, 75%, 95%);
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  padding-top: 1rem;

  ${({ spaceBetween }) => spaceBetween && 'justify-content: space-between;'};
`;

export const Header = (props) => (
  <HeaderContainer noPadding={props.noPadding}>
    <TitleWrapper>
      <Title>{props.title}</Title>
    </TitleWrapper>
    <IconButton aria-label="Close" onClick={props.onClose} size="xs" type="button">
      <FontAwesomeIcon icon={faTimes} />
    </IconButton>
  </HeaderContainer>
);
